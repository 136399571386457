import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Pagination } from "swiper";
import FancyTitle from "./fancy-title";

import "swiper/css";
import "swiper/css/virtual";
import "swiper/css/grid";

function SliderTestimonials({
  data,
  title = "Danke für das Lob",
  subtitle = "Testimonials",
  imageClasses,
}) {
  const result = useStaticQuery(
    graphql`
      query {
        allTestimonialsYaml {
          edges {
            node {
              title
              subtitle
              description
              imageHeight
              stars {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              profile {
                childImageSharp {
                  gatsbyImageData(
                    width: 60
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              image {
                childImageSharp {
                  gatsbyImageData(
                    height: 50
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              imageLight {
                childImageSharp {
                  gatsbyImageData(
                    height: 50
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    `
  );

  const testimonials =
    data && data.length
      ? data.map((item) => item.node)
      : result?.allTestimonialsYaml.edges.map((edge) => edge.node);

  if (!testimonials || testimonials.length === 0) {
    return null;
  }

  return (
    <div>
      <FancyTitle
        tag="h2"
        text={title}
        subtitle={subtitle}
        textAlign="text-center lg:text-center"
      />
      <div>
        <Swiper
          modules={[Pagination]}
          slidesPerView="auto"
          className="w-full testimonials-slider"
          centeredSlides
          loop
          spaceBetween={20}
          initialSlide={0}
          pagination={{
            clickable: true,
            renderBullet(index, className) {
              return `<span class="${className}">${index + 1}</span>`;
            },
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            1280: {
              slidesPerView: 3,
              initialSlide: 0,
            },
          }}
        >
          {testimonials &&
            testimonials.length !== 0 &&
            testimonials.map((item) => {
              const image = getImage(item.image);
              const imageLight = getImage(item.imageLight);
              const profile = getImage(item.profile);
              const stars = getImage(item.stars);
              return (
                <SwiperSlide key={item.title} className="!h-auto">
                  <div className="border-2 rounded border-latori-green-dark p-6 lg:p-8 gap-2 lg:gap-4 flex flex-col justify-start items-center h-full">
                    {image && (
                      <GatsbyImage
                        image={image}
                        className={`${
                          imageLight ? "hidden dark:inline-block" : ""
                        } mb-5${imageClasses || ""}`}
                        alt={item.title}
                        objectFit="contain"
                        style={{
                          maxWidth: "150px",
                          width: item.imageWidth || "",
                          height: item.imageWidth || "",
                        }}
                      />
                    )}
                    {imageLight && (
                      <GatsbyImage
                        image={imageLight}
                        className="dark:hidden mb-5"
                        objectFit="contain"
                        alt={item.title}
                        style={{
                          maxWidth: "150px",
                          width: item.imageWidth || "",
                          height: item.imageWidth || "",
                        }}
                      />
                    )}
                    <div className="flex justify-center items-center gap-4">
                      {profile && (
                        <div className="bg-black p-[1px] rounded-full">
                          <GatsbyImage
                            image={profile}
                            alt={item.title}
                            objectFit="contain"
                            className=" "
                          />
                        </div>
                      )}
                      <FancyTitle
                        tag="h3"
                        text={item.title}
                        fontSize="text-xl xl:text-2xl"
                        marginBottom="mb-0"
                        styleOverrides="fancy-title-testimonials"
                      />
                    </div>
                    <div className="xl:mt-4">
                      {stars && (
                        <GatsbyImage
                          image={stars}
                          alt={item.title}
                          objectFit="contain"
                          className=" "
                        />
                      )}
                    </div>
                    <div className="xl:mt-4">
                      <p
                        className="dark:prose-invert text-center"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
}

export default SliderTestimonials;
