import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { getCookie, deleteCookie } from '../lib/utils';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';
import FancyTitle from '../components/fancy-title';
import SliderTestimonials from '../components/slider-testimonials';

function LaunchplanPage() {
  const [mounted, setMounted] = React.useState(false);
  const [isKlaviyoAccepted, setIsKlaviyoAccepted] = React.useState(false);
  const formRef = React.useRef();

  React.useEffect(() => {
    setMounted(true);
    const checkKlaviyoCookie = () => {
      const klaviyoCookie = getCookie('gatsby-gdpr-klaviyo');
      setIsKlaviyoAccepted(klaviyoCookie === 'true');
    };

    checkKlaviyoCookie();
    const intervalId = setInterval(checkKlaviyoCookie, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const scrollFormInView = () => {
    formRef.current?.scrollIntoView({ behaviour: 'smooth', block: 'center' });
  };

  function openCookieSettings() {
    Object.keys(window.gatsbyPluginGDPRCookiesOptions).forEach((key) => {
      if (key !== 'environemnts' && key !== 'plugins' && key) {
        localStorage.removeItem(window.gatsbyPluginGDPRCookiesOptions[key].cookieName);
        if (
          getCookie(window.gatsbyPluginGDPRCookiesOptions[key].cookieName) !== ''
        ) {
          localStorage.setItem(
            window.gatsbyPluginGDPRCookiesOptions[key].cookieName,
            getCookie(window.gatsbyPluginGDPRCookiesOptions[key].cookieName),
          );
        }
        deleteCookie(window.gatsbyPluginGDPRCookiesOptions[key].cookieName);
      }
    });

    window.location.reload();
  }

  const service = {};
  service.usps = [
    {
      title: 'Kostenlos',
      description: 'Den Leitfaden mit Checkliste können Sie sich kostenfrei herunterladen. Füllen Sie einfach das Formular aus und wir senden Ihnen unseren Launchplan umgehend zu.',
      icon: 'shuffle',
      backdrop_x: 35,
      backdrop_y: 6,
    },
    {
      title: 'Übersichtlich',
      description: 'Keine ellenlangen Texte, sondern Checkboxen! Mit der Checklist behalten Sie alle wichtigen To-Dos für Ihren Shop-Livegang immer im Blick und können diese direkt abhaken.',
      icon: 'table-picnic',
      backdrop_x: 15,
      backdrop_y: 15,
    },
    {
      title: 'Direkt umsetzbar',
      description: 'Von der Planung bis hin zu wertvollen Tipps ist alles im kostenlosen Launchplan enthalten. Die meisten To-Dos können Sie direkt angehen!',
      icon: 'shopify',
      backdrop_x: 5,
      backdrop_y: 25,
    },
  ];

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: 'LaunchplanPage', fullWidth: true });
        return (
          <>
            <Seo title="kostenloser Shopify-Launchplan" />
            <div className="px-4 lg:px-16 pt-20 lg:pt-36">
              <div>
                <div className="flex flex-col items-center gap-16 flex-nowrap justify-between xl:flex-row xl:flex xl:justify-between xl:items-start">
                  <div className="xl:w-1/2">
                    <h1 className="inline-block relative text-3xl lg:text-5xl leading-normal font-bold fancy-title w-full mb-6">Bringen Sie Ihren Shopify Plus Shop fehlerfrei online!</h1>
                    <p className="inline-block relative text-xl w-full">
                      Unsere kostenlose Checkliste hilft Ihnen dabei, alle wichtigen
                      Schritte im Blick zu behalten und erfolgreich durchzustarten!
                    </p>
                  </div>
                  <div className="xl:w-1/2">
                    <StaticImage
                      alt="Jan Laußmann - Co-Founder & CEO"
                      className="mb-4"
                      src="./../assets/launchplan-servicepage.png"
                      // width={400}
                      // height={400}
                    />
                  </div>
                </div>
              </div>

              {mounted && !isKlaviyoAccepted ? (
                <div className="min-h-[50px] my-10">
                  <button
                    type="button"
                    className="underline cursor-pointer"
                    onClick={openCookieSettings}
                  >
                    Bitte stimmen Sie erst den Cookies zu,
                  </button>
                  <p>
                    {' '}
                    um das kostenlose Shopify-Launchplan-Formular zu laden.
                  </p>
                </div>
              ) : (
                <div
                  className="klaviyo-form-RYTZnv cursor-pointer my-10"
                  id="launchplan-form"
                  data-klaviyo-accepted={isKlaviyoAccepted}
                  ref={formRef}
                />
              )}
              <div>
                { service?.usps && (
                <div className="pb-24 pt-6 grid grid-cols-1 lg:grid-cols-3 gap-20 lg:gap-4 xl:gap-20 highlighted-wrapper">
                  { service.usps?.map((usp, i = 1) => (
                    <div className="pt-5 pb-10 px-5 border-solid border-latori-green-dark border-2 rounded" key={`t-${usp.title}`}>
                      <div className="flex items-center dark w-full">
                        <style type="text/css">
                          {`
                        .highlighted-wrapper > div:nth-child(${i + 1}) .green-background-highlight::before {
                          top: ${usp.backdrop_y}%;
                          right: ${usp.backdrop_x}%;
                        }
                        `}

                        </style>
                        <FancyTitle
                          text={usp.title}
                          marginBottom=""
                          fontSize="text-2xl"
                          className="fancy-title-small w-[calc(100%+16px)] p-[10px] hyphens-auto
                        bg-background-dark text-white green-background-highlight mx-[-10px]"
                          tag="h3"
                        />
                      </div>
                      <p className=" leading-6 pt-6" dangerouslySetInnerHTML={{ __html: usp.description }} />
                    </div>
                  ))}
                </div>
                )}
              </div>
              <div>
                <SliderTestimonials title="Unsere Shopify-Expertise - bestätigt von unseren Shopify-Händlern" />
              </div>
              <div>
                <div className="flex flex-col lg:flex-row lg:items-center gap-x-14">
                  <div>
                    <h2 className="text-3xl mt-10 fancy-title mb-5 font-bold lg:text-5xl">Das ist in unserem Launchplan enthalten</h2>

                    <div className="mt-14 block lg:hidden mb-4">
                      <StaticImage
                        alt="Jan Laußmann - Co-Founder & CEO"
                        className="mb-4 w-full lg:max-w-[60%]"
                        src="./../assets/Go-Live-Checkliste-blurred.png"
                      />

                    </div>
                    <p>
                      Mit dieser Checkliste behalten Sie den Überblick und vermeiden
                      potenzielle Stolpersteine – perfekt für jeden Händler, der seinen Shopify Plus
                      Store professionell und stressfrei live bringen möchte!
                    </p>
                    <ul className="ps-5 my-10">
                      <li>✓ über 6 Seiten Erklärung und Checklist</li>
                      <li>
                        ✓ Inhalte von strategischer Planung und
                        technischen Anforderungen bis hin zu Marketing-Tipps
                        und rechtlichen Aspekten
                      </li>
                      <li>✓ Schritt-für-Schritt-Anleitung durch den gesamten Prozess geführt</li>
                    </ul>
                    <button
                      className="block button-primary mt-12 max-w-fit"
                      type="button"
                      onClick={scrollFormInView}
                    >
                      Jetzt Checkliste herunterladen
                    </button>
                  </div>
                  <div className="mt-14">
                    <StaticImage
                      alt="Jan Laußmann - Co-Founder & CEO"
                      className="mb-4 w-full lg:max-w-[60%] hidden lg:block"
                      src="./../assets/Go-Live-Checkliste-blurred.png"
                    />

                  </div>
                </div>
              </div>
            </div>
            <div className="my-24 lg:my-60" />
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default LaunchplanPage;
